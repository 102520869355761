<template>
  <AKModuleContainer ref="akModuleContainer">
    <template #moduleHeaderActions>
      <GeneralTournamentSelect
        class="z-module-layer-flex-2"
        @on-tournament-select="updateCurrentTournament"
        @on-tournament-clear="clearCurrentTournament" />
    </template>
    <AKLoadingContent
      v-if="matchesdataHandler.data.elementsAreLoading"
      class="ak-container-main-overlay-default z-module-overlay-layer" />
    <div v-else class="ak-scrollbar-primary flex grow flex-col overflow-x-hidden rounded-b-xl bg-white p-4">
      <div v-if="uniqueMatchDays && uniqueMatchDays.length">
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <div v-for="day in uniqueMatchDays" :key="day" class="relative grow">
            <slot name="grid"></slot>
            <AKCard class="mx-2 flex-col px-2 sm:w-full">
              <template #title>
                {{ day + '.' + ' ' + $t('MatchPlanPageView.matchday') }}
              </template>
              <MatchdayDataTable :matches="getMatchesForDay(day)" :matchday="day" @on-row-click="onRowClick" />
            </AKCard>
          </div>
        </div>
      </div>
    </div>

    <template #sidePanel>
      <AKLoadingContent
        v-if="matchesdataHandler.data.elementIsLoading"
        class="ak-container-main-overlay-default z-module-overlay-layer" />
      <MatchDataSidePanel
        v-if="matchesdataHandler.data.current_match"
        :key="matchesdataHandler.data.current_match.match_id"
        @close-side-panel="closeSidePanel"
        @maximize-side-panel="maximizeSidePanel"
        @minimize-side-panel="minimizeSidePanel" />
    </template>
  </AKModuleContainer>
</template>

<script setup lang="ts">
import { AKDataTableRowClickEvent } from '@ak_tools/components/data';
import AKModuleContainer from '@ak_tools/components/layout/AKModuleContainer.vue';
import { AKLoadingContent } from '@ak_tools/components/overlay';
import AKCard from '@ak_tools/components/panel/AKCard.vue';
import { AKStore } from '@ak_tools/start/App';
import { computed, nextTick, onMounted, provide, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import { BECommMatch } from '@/backend_communication/definitions/BECommMatches';
import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments';
import GeneralTournamentSelect from '@/components/layout/select/GeneralTournamentSelect.vue';
import { TournamentSelectDataHandler } from '@/components/layout/select/TournamentSelectDataHandler';
import MatchDataSidePanel from '@/components/matches/MatchDataSidePanel.vue';
import MatchdayDataTable from '@/components/matches/MatchdayDataTable.vue';
import { MatchesDataHandler } from '@/components/matches/MatchesDataHandler';

const { locale } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const matchesdataHandler = ref(new MatchesDataHandler());
const tournamentSelectDataHandler = ref(new TournamentSelectDataHandler());
const akModuleContainer = ref<InstanceType<typeof AKModuleContainer> | null>(null);
const router = useRouter();
const route = useRoute();
const currentRouteName = String(route.name);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const currentRouterItemId = computed(() => {
  return Number(route.params.item_id);
});

const uniqueMatchDays = computed(() => {
  const matchDays = new Set<number>();
  if (!matchesdataHandler.value.data.matches) {
    return [];
  }
  matchesdataHandler.value.data.matches.forEach((match: BECommMatch) => {
    matchDays.add(match.match_day_number);
  });
  return Array.from(matchDays);
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const closeSidePanel = () => {
  resetView();
  matchesdataHandler.value.data.selectedTableRows = [];
};
const maximizeSidePanel = () => {
  akModuleContainer.value?.maximizeSidePanel();
};

const minimizeSidePanel = () => {
  akModuleContainer.value?.minimizeSidePanel();
};

const onRowClick = (_: AKDataTableRowClickEvent, matchRowData: BECommMatch) => {
  const match_id = matchRowData.match_id;
  nextTick(() => {
    addApplicationIdToRoute(match_id);
    showSidePanel();
  });
};

const addApplicationIdToRoute = (match_id: number) => {
  if (currentRouterItemId.value !== match_id) {
    router.push({
      name: currentRouteName,
      params: { item_id: match_id },
    });
  }
};

const resetView = () => {
  akModuleContainer.value?.closeSidePanel();
  matchesdataHandler.value.data.current_match = null;
  updateRoute();
};
const updateRoute = () => {
  const match_id = matchesdataHandler.value.data.current_match?.match_id || null;

  router.push({ name: currentRouteName, params: { item_id: match_id } });
};

const showSidePanel = () => {
  akModuleContainer.value?.showSidePanel();
};

const updateCurrentItem = async () => {
  if (currentRouterItemId.value > 0) {
    openItemById(currentRouterItemId.value);
  }
};
const openItemById = async (id: number) => {
  if (id !== matchesdataHandler.value.data.current_match?.match_id) {
    await matchesdataHandler.value.getMatchById(currentRouterItemId.value);
    if (!matchesdataHandler.value.data.current_match) {
      resetView();
    } else {
      showSidePanel();
    }
  }
};
const getMatchesForDay = (day: number) => {
  return matchesdataHandler.value.data.matches.filter((match: BECommMatch) => match.match_day_number === day);
};

const updateCurrentTournament = (tournament: BECommTournament) => {
  matchesdataHandler.value.data.current_tournament = tournament;
};

const clearCurrentTournament = () => {
  tournamentSelectDataHandler.value.data.current_tournament = null;
  matchesdataHandler.value.data.matches = [];
};
const setModuleTitle = () => {
  AKStore.App.state.currentModuleTitle = 'Spielplan';
};

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(currentRouterItemId, async () => {
  await updateCurrentItem();
});

watch(
  () => matchesdataHandler.value.data.current_tournament,
  async (newTournament) => {
    if (newTournament) {
      await matchesdataHandler.value.getMatchesFromBackend();
    } else {
      matchesdataHandler.value.data.matches = [];
    }
  },
);

watch(locale, () => {
  setModuleTitle();
});
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
provide('MatchesDataHandler', matchesdataHandler.value);
provide('TournamentSelectDataHandler', tournamentSelectDataHandler.value);

//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(async () => {
  await tournamentSelectDataHandler.value.fetchTournaments();
  matchesdataHandler.value.data.current_tournament = tournamentSelectDataHandler.value.data.current_tournament;
  await updateCurrentItem();
});

setModuleTitle();
</script>

<style lang="sass" scoped></style>
