<template>
  <AKSidePanel
    v-model:default-label-cols="labelCols"
    @delete-item="deleteItem"
    @close-side-panel="emitCloseSidePanel"
    @maximize-side-panel="emitMaximizeSidePanel"
    @minimize-side-panel="emitMinimizeSidePanel">
    <AKTextarea
      v-model="tournamentName"
      class="w-full"
      data-test="`textarea-tournament-title"
      lazy
      enable-switch-read-write-mode
      :placeholder="$t('TournamentDataSidePanel.title.label')"
      font-style="headline"
      font-size="h2"
      @update:model-value="updateTournament" />

    <AKDivider />

    <AKCalendar
      v-model="startDate"
      :label="$t('TournamentDataSidePanel.start-date.label')"
      enable-switch-read-write-mode
      :label-cols="labelCols"
      @update:model-value="updateTournament">
    </AKCalendar>
    <AKCalendar
      v-model="endDate"
      :label="$t('TournamentDataSidePanel.end-date.label')"
      enable-switch-read-write-mode
      :label-cols="labelCols"
      @update:model-value="updateTournament">
    </AKCalendar>
    <AKTooltip :triggers="['hover']" :text="$t('AKTooltip.matchDuration')">
      <AKInputNumber
        v-model="matchDuration"
        data-test="example-min-max"
        enable-switch-read-write-mode
        lazy
        :min-value="5"
        :max-value="90"
        :label="$t('TournamentDataSidePanel.input-number.duration')"
        :label-cols="labelCols" />
    </AKTooltip>
    <AKInputContainer :label="$t('TournamentDataSidePanel.timeslots.label')" :label-cols="labelCols">
      <div class="flex flex-wrap items-center gap-2 py-1">
        <AKTag
          v-for="timeslot in providedTournamentHandler.data.timeslots"
          :key="timeslot.timeslot_id"
          class="... transform motion-safe:hover:scale-110"
          data-test="tournament-badge">
          <span class="text-base font-normal" v-text="timeslot.start + ' - ' + timeslot.end"></span>
        </AKTag>
      </div>
    </AKInputContainer>

    <AKDivider />

    <AKSelectPerson
      :model-value="providedTournamentHandler.data.current_tournament?.participants"
      enable-switch-read-write-mode
      :multiselect="true"
      :label-cols="labelCols"
      :label="$t('TournamentDataSidePanel.tournament-group.label')"
      @on-select="addPersonToTournament"
      @on-remove="removePersonFromTournament"></AKSelectPerson>

    <AKDivider />

    <AKSelect
      v-model="providedTournamentHandler.data.current_tournament!.locations"
      :label="$t('TournamentDataSidePanel.location-overview.label')"
      :label-cols="labelCols"
      track-by="location_id"
      :selectable-options="locations"
      :multi-select-tag-content="multiSelectTagContent"
      :multiselect="true"
      allow-create-tags
      @on-select="addLocation"
      @on-remove="removeLocation"
      @on-create-tag="createLocation">
      <template #option="{ option }">
        <p>{{ option.name }}</p>
      </template>
    </AKSelect>

    <AKDivider />

    <AKButton
      class="py-1"
      :label="$t('TournamentDataSidePanel.button.create-match-plan.label')"
      @click="generateMatchPlan()">
    </AKButton>
  </AKSidePanel>
</template>

<script setup lang="ts">
import { AKBECommPersonEmployeeUserSchema } from '@ak_tools/backend_communication/definitions/hr/AKBECommPersons';
import AKButton from '@ak_tools/components/button/AKButton.vue';
import { AKTextarea } from '@ak_tools/components/form';
import AKCalendar from '@ak_tools/components/form/AKCalendar.vue';
import AKInputNumber from '@ak_tools/components/form/AKInputNumber.vue';
import { AKInputContainer, AKSidePanel } from '@ak_tools/components/layout';
import AKTag from '@ak_tools/components/misc/AKTag.vue';
import AKTooltip from '@ak_tools/components/overlay/AKTooltip.vue';
import AKDivider from '@ak_tools/components/panel/AKDivider.vue';
import AKSelect from '@ak_tools/components/select/AKSelect.vue';
import AKSelectPerson from '@ak_tools/components/select/AKSelectPerson.vue';
import { AKDateTime } from '@ak_tools/ts_modules/core/AKDateTime';
import { computed, inject, ref, watch } from 'vue';

import { BECommLocation } from '@/backend_communication/definitions/BECommLocations';
import { TournamentDataHandler } from '@/components/tournaments/TournamentDataHandler';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const providedTournamentHandler = inject('TournamentDataHandler') as TournamentDataHandler;
const labelCols = ref(4);
const akDateTime = new AKDateTime();

const multiSelectTagContent = (location: BECommLocation) => {
  return location.name;
};

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  -------------------------------------------------------------------------------------------------------------------
const endDate = computed({
  get() {
    const { current_tournament } = providedTournamentHandler.data;
    if (!current_tournament || !current_tournament.end_date) {
      return new Date();
    }
    return akDateTime.getDateFromTimeStamp(current_tournament.end_date);
  },
  set(newEndDate) {
    let convertedEndDate = akDateTime.getTimeStampFromDate(newEndDate);
    if (providedTournamentHandler.data.current_tournament) {
      if (convertedEndDate < providedTournamentHandler.data.current_tournament.start_date) {
        convertedEndDate = providedTournamentHandler.data.current_tournament.start_date;
      }
      providedTournamentHandler.data.current_tournament.end_date = convertedEndDate;
    }
  },
});
const startDate = computed({
  get() {
    const { current_tournament } = providedTournamentHandler.data;
    if (!current_tournament || !current_tournament.start_date) {
      return new Date();
    }
    return akDateTime.getDateFromTimeStamp(current_tournament.start_date);
  },
  set(newStartDate) {
    if (providedTournamentHandler.data.current_tournament) {
      providedTournamentHandler.data.current_tournament.start_date = akDateTime.getTimeStampFromDate(newStartDate);
      if (startDate.value > endDate.value) {
        endDate.value = newStartDate;
      }
    }
  },
});

const matchDuration = computed({
  get() {
    return providedTournamentHandler.data.current_tournament?.match_duration ?? undefined;
  },

  set(newMatchDuration) {
    if (providedTournamentHandler.data.current_tournament) {
      providedTournamentHandler.data.current_tournament.match_duration =
        newMatchDuration || newMatchDuration === 0 ? newMatchDuration : null;
    }
  },
});

const tournamentName = computed({
  get() {
    return providedTournamentHandler.data.current_tournament?.name ?? undefined;
  },
  set(newName: string) {
    if (providedTournamentHandler.data.current_tournament) {
      providedTournamentHandler.data.current_tournament.name = newName;
    }
  },
});
const locations = computed({
  get() {
    return providedTournamentHandler.data.all_locations;
  },
  set() {},
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------

const addLocation = (location: BECommLocation) => {
  const location_id = location.location_id;
  providedTournamentHandler.addLocationToCurrentTournament(location_id);
};

const removeLocation = (location: BECommLocation) => {
  const location_id = location.location_id;
  providedTournamentHandler.removeLocationFromTournament(location_id);
};

const createLocation = (name: string) => {
  providedTournamentHandler.createLocation(name);
};

const addPersonToTournament = (person: AKBECommPersonEmployeeUserSchema) => {
  if (person.user) {
    if (person.user.personal_user_group) {
      providedTournamentHandler.addParticipantToCurrentTournament(person.user.personal_user_group.user_group_id);
    }
  }
};
const removePersonFromTournament = (person: AKBECommPersonEmployeeUserSchema) => {
  let participantId = null;
  providedTournamentHandler.data.current_tournament?.participants.forEach((participant) => {
    if (participant.user?.personal_user_group?.user_group_id === person.user?.personal_user_group?.user_group_id) {
      participantId = participant.participant_id;
    }
  });
  if (participantId) {
    providedTournamentHandler.removeParticipantFromCurrentTournament(participantId);
  }
};

const updateOrGenerateTimeslots = async () => {
  if (!providedTournamentHandler.data.current_tournament?.match_duration) {
    return;
  }
  await providedTournamentHandler.getTimeslotsByMatchDurationFromBackend();
};

const deleteItem = async () => {
  const tournament = providedTournamentHandler.data.current_tournament;
  if (tournament) {
    providedTournamentHandler.deleteTournamentByIdOnBackend(tournament.tournament_id);
  }
  emitCloseSidePanel();
};

const updateTournament = async () => {
  if (providedTournamentHandler.data.current_tournament) {
    providedTournamentHandler.updateTournamentBaseOnBackend(providedTournamentHandler.data.current_tournament);
  }
};

const generateMatchPlan = async () => {
  const tournament_id = providedTournamentHandler.data.current_tournament!.tournament_id;
  await providedTournamentHandler.generateMatchPlan(tournament_id);
};

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(matchDuration, async () => {
  await updateOrGenerateTimeslots();
  await updateTournament();
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  closeSidePanel: [];
  maximizeSidePanel: [];
  minimizeSidePanel: [];
}>();

const emitCloseSidePanel = () => {
  emits('closeSidePanel');
};

const emitMaximizeSidePanel = () => {
  emits('maximizeSidePanel');
};

const emitMinimizeSidePanel = () => {
  emits('minimizeSidePanel');
};
</script>

<style lang="sass" scoped></style>
