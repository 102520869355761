import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';

import {
  BECommAddTimeslotRequest,
  BECommTimeslotCreateResponseR,
  BECommTimeslotDeleteResponseR,
  BECommTimeslotGeneratorRequest,
  BECommTimeslotGetResponseR,
  BECommTimeslotsGetResponseR,
  BECommTimeslotUpdateRequest,
  BECommTimeslotUpdateResponseR,
} from '../definitions/BECommTimeslots';

export default class BECommTimeslots extends BeCommAll {
  public beCommGetTimeslots(): Promise<BECommTimeslotsGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTimeslots.error.get.timeslots.message'),
    };
    return this.beComm.backendCommGet(`/timeslots`, {}, messages, {
      returnOnError: false,
    });
  }

  public beCommGetTimeslotById(timeslot_id: number): Promise<BECommTimeslotGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTimeslots.error.get.timeslot.message'),
    };

    return this.beComm.backendCommGet(`/timeslots/${timeslot_id}`, { timeout: 10000 }, messages, {
      returnOnError: false,
    });
  }

  public beCommTimeslotsByDuration(duration: number): Promise<BECommTimeslotsGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournaments.error.get.timeslots-by-duration.message'),
    };

    return this.beComm.backendCommGet(`/timeslots/by-match-duration/${duration}`, { timeout: 10000 }, messages, {
      returnOnError: false,
    });
  }

  public beCommDeleteTimeslot(timeslotId: number): Promise<BECommTimeslotDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTimeslots.error.delete.timeslot.message'),
    };

    return this.beComm.backendCommDelete(`/timeslots/${timeslotId}`, {}, messages);
  }

  public beCommCreateTimeslot(start: string, end: string, duration: number): Promise<BECommTimeslotCreateResponseR> {
    const postBody: BECommAddTimeslotRequest = {
      start: start,
      end: end,
      duration: duration,
    };

    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTimeslots.error.post.timeslot.message'),
    };

    return this.beComm.backendCommPost('/timeslots', postBody, {}, messages);
  }

  public beCommUpdateTimeslot(
    timeslot_id: number,
    putBody: BECommTimeslotUpdateRequest,
  ): Promise<BECommTimeslotUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTimeslots.error.update.timeslot.message'),
    };

    return this.beComm.backendCommPut(`/timeslots/${timeslot_id}`, putBody, {}, messages);
  }

  public beCommGenerateTimeslot(duration_in_minutes: string): Promise<BECommTimeslotsGetResponseR> {
    const postBody: BECommTimeslotGeneratorRequest = { duration_in_minutes };

    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTimeslots.error.create.timeslots.message'),
    };

    return this.beComm.backendCommPost('/generate-timeslots', postBody, {}, messages);
  }

  public async beCommGetTimeslotByMatchDuration(match_duration: number): Promise<BECommTimeslotsGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTimeslots.error.get.timeslot-by-match-duration.message'),
    };
    return this.beComm.backendCommGet(`/timeslots/by-match-duration/${match_duration}`, {}, messages);
  }
}
