import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';

import { BECommLocationsGetResponseR } from '../definitions/BECommLocations';
import { BECommMatchesGetResponseR } from '../definitions/BECommMatches';
import {
  BECommParticipant,
  BECommTournamentCreateResponseR,
  BECommTournamentDeleteResponseR,
  BECommTournamentGetResponseR,
  BECommTournamentParticipantsPostRequest,
  BECommTournamentsGetResponseR,
  BECommTournamentUpdateRequest,
  BECommTournamentUpdateResponseR,
} from '../definitions/BECommTournaments';

export default class BECommTournaments extends BeCommAll {
  public beCommGetTournaments(): Promise<BECommTournamentsGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournaments.error.get.tournaments.message'),
    };
    return this.beComm.backendCommGet(`/tournaments`, {}, messages, {
      returnOnError: false,
    });
  }

  public beCommGetLocations(): Promise<BECommLocationsGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommLocations.error.get.locations.message'),
    };
    return this.beComm.backendCommGet(`/locations`, {}, messages, {
      returnOnError: false,
    });
  }

  public beCommGetTournamentById(tournament_id: number): Promise<BECommTournamentGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournaments.error.get.tournament.message'),
    };

    return this.beComm.backendCommGet(`/tournaments/${tournament_id}`, { timeout: 10000 }, messages, {
      returnOnError: false,
    });
  }

  public beCommDeleteTournament(tournamentId: number): Promise<BECommTournamentDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournaments.error.delete.tournament.message'),
    };

    return this.beComm.backendCommDelete(`/tournaments/${tournamentId}`, {}, messages);
  }

  public beCommCreateTournament(): Promise<BECommTournamentCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournaments.error.post.tournament.message'),
    };

    return this.beComm.backendCommPost('/tournaments', {}, messages);
  }

  public beCommUpdateTournament(
    tournament_id: number,
    putBody: BECommTournamentUpdateRequest,
  ): Promise<BECommTournamentUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournaments.error.update.tournament.message'),
    };

    return this.beComm.backendCommPut(`/tournaments/${tournament_id}`, putBody, {}, messages);
  }

  public beAddParticipantToTournament(postBody: BECommTournamentParticipantsPostRequest): Promise<BECommParticipant> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournaments.error.post.participant.message'),
    };
    return this.beComm.backendCommPost(`/tournament/participants`, postBody, messages);
  }

  public beRemoveParticipantFromTournament(
    personal_user_group_id: number,
    tournament_id: number,
  ): Promise<BECommTournamentDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournaments.error.delete.participant.message'),
    };
    return this.beComm.backendCommDelete(
      `/tournament/participants/${tournament_id}/${personal_user_group_id}`,
      {},
      messages,
    );
  }

  public beCommGenerateMatchPlan(tournament_id: number): Promise<BECommMatchesGetResponseR> {
    const postBody = { tournament_id: tournament_id };

    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommTournaments.error.post.match-plan-generation.message'),
    };
    return this.beComm.backendCommPost('/generate-matchplan', postBody, { timeout: 120000 }, messages);
  }
}
