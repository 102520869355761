import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';

import {
  BECommLocationCreateResponseR,
  BECommLocationDeleteResponseR,
  BECommLocationGetResponseR,
  BECommLocationsGetResponseR,
  BECommLocationUpdateRequest,
  BECommLocationUpdateResponseR,
} from '../definitions/BECommLocations';

export default class BECommLocations extends BeCommAll {
  public beCommGetLocations(tournamentId: number | null): Promise<BECommLocationsGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommLocations.error.get.locations.message'),
    };
    return this.beComm.backendCommGet(`/locations?tournament_id=${tournamentId}`, {}, messages, {
      returnOnError: false,
    });
  }

  public beCommGetLocationById(location_id: number): Promise<BECommLocationGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommLocations.error.get.location.message'),
    };

    return this.beComm.backendCommGet(`/locations/${location_id}`, { timeout: 10000 }, messages, {
      returnOnError: false,
    });
  }

  public beCommDeleteLocation(locationId: number): Promise<BECommLocationDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommLocations.error.delete.location.message'),
    };

    return this.beComm.backendCommDelete(`/locations/${locationId}`, {}, messages);
  }

  public beCommCreateLocation(): Promise<BECommLocationCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommLocations.error.post.location.message'),
    };

    return this.beComm.backendCommPost('/locations', {}, messages);
  }

  public beCommUpdateLocation(
    location_id: number,
    putBody: BECommLocationUpdateRequest,
  ): Promise<BECommLocationUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommLocations.error.update.location.message'),
    };

    return this.beComm.backendCommPut(`/locations/${location_id}`, putBody, {}, messages);
  }
}
