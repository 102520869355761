<template>
  <div
    ref="dropZoneRef"
    class="relative flex w-full items-center rounded-lg border"
    :class="{ ' bg-backdrop': isOverDropZone }">
    <div class="absolute h-full w-full" />

    <div class="relative mx-auto flex flex-wrap items-center py-10">
      <AKIcon class="mr-2" :framework-icon="PrimeIcons.CLOUD_UPLOAD" />
      <span v-text="$t('AKDropzone.dropzone-drop-file')" />
      <AKButton
        class="relative ml-2 w-auto"
        :label="$t('AKDropzone.dropzone-select-files')"
        size="small"
        :icon="PrimeIcons.UPLOAD"
        icon-pos="right"
        @click="onUploadButton">
      </AKButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AKButton } from '@ak_tools/components/button';
import { AKIcon } from '@ak_tools/components/media';
import { AKFileListItem } from '@ak_tools/components/misc';
import { useDropZone, useFileSystemAccess } from '@vueuse/core';
import { PrimeIcons } from 'primevue/api';
import { ref } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const dropzoneHover = ref(false);
const dataType = ref<'Text' | 'ArrayBuffer' | 'Blob'>('Blob');
const dropZoneRef = ref<HTMLDivElement>();
const uploadFile = ref<AKFileListItem[]>([]);
const uploadDialog = useFileSystemAccess({
  dataType,
  types: [
    {
      accept: {},
    },
  ],

  excludeAcceptAllOption: true,
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const fileToFileListMapping = (file: File): AKFileListItem => {
  return {
    name: file.name,
    type: file.type,
    size: file.size,
    lastModified: file.lastModified,
    fileObject: file,
  };
};

const onDrop = (files: File[] | null) => {
  dropzoneHover.value = false;
  if (files) {
    const newFiles: AKFileListItem[] = files.map(fileToFileListMapping);
    emitUploadFiles(newFiles);
  }
};

const onUploadButton = async () => {
  await uploadDialog.open();

  if (uploadDialog.file.value) {
    const newFile = fileToFileListMapping(uploadDialog.file.value as File);
    uploadFile.value.push(newFile);
    emitUploadFiles([newFile]);
  }
};

//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  uploadFiles: [files: AKFileListItem[]];
}>();

const emitUploadFiles = (files: AKFileListItem[]) => {
  emits('uploadFiles', files);
};

//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
const { isOverDropZone } = useDropZone(dropZoneRef, onDrop);
</script>

<style lang="sass" scoped></style>
