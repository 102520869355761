<template>
  <div
    v-if="mainMenuIsMaximized"
    class="flex w-full items-center justify-stretch overflow-hidden truncate px-4 text-lg">
    <div class="my-1 max-w-full grow py-1">
      <router-link
        v-if="props.item.link"
        class="text-theme flex items-center hover:font-bold"
        :to="{ name: props.item.name }">
        <AKRouterMenuItemInner
          :current-item-is-active="currentItemIsActive"
          :icon="props.item.icon"
          :title="props.item.title"
          @on-menu-item-click="emitOnMenuItemClick" />
      </router-link>
      <div v-else class="flex cursor-pointer items-center hover:font-bold" @click="toggleChildren()">
        <AKRouterMenuItemInner
          :current-item-is-active="currentItemIsActive"
          :icon="props.item.icon"
          :title="props.item.title" />
      </div>
    </div>
    <div class="flex-none">
      <AKIcon
        v-if="itemHasChildren"
        class="transform cursor-pointer transition duration-200"
        :scale="0.75"
        :framework-icon="PrimeIcons.CHEVRON_RIGHT"
        :class="{ 'rotate-90': showChildren }"
        @click="toggleChildren()" />
    </div>
  </div>
  <div
    v-else-if="props.item.icon"
    :id="`akIconContainer${props.item.name}`"
    ref="akIconContainer"
    class="my-1 flex w-full items-center justify-center py-1"
    @mouseenter="showTieredMenu"
    @mouseleave="hideTieredMenu">
    <div v-if="props.item.link">
      <router-link :to="{ name: props.item.name }">
        <AKTooltip :text="$t(props.item.title)" placement="right" :tooltip-distance="15">
          <AKIcon class="cursor-pointer" :bold="currentItemIsActive" :framework-icon="props.item.icon" :scale="1.25" />
        </AKTooltip>
      </router-link>
    </div>
    <div v-else>
      <AKIcon class="cursor-pointer" :bold="currentItemIsActive" :framework-icon="props.item.icon" :scale="1.25" />
    </div>
    <div v-if="itemHasChildren">
      <AKTieredMenu
        ref="akTieredMenu"
        :style="tieredMenuStyle"
        :items="matchedChildren"
        :append-to="`#${akIconContainer?.id}`" />
    </div>
  </div>
  <div
    v-if="itemHasChildren && showChildren && mainMenuIsMaximized"
    class="mb-4 ml-4 mt-2 grow border-l border-white pl-2">
    <AKRouterMenuItem
      v-for="(itemChild, index) in item.children"
      :key="index"
      :item="itemChild"
      is-child
      @on-menu-item-click="emitOnMenuItemClick" />
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_AppNavigationItem = AKTheMainMenuItem & {
  /**
   * List of Items which can be viewed by particular rights
   * "all" means everyone can see this item
   * If it does not exist it means "all"
   */
  viewRights?: Array<string>;
  groupRights?: Array<string>;
  children?: Array<AppNavigationItem>;
  vueComponent?: Component;
  order?: number;
  hideInNav?: boolean;
}
interface PropsInterface {
  item: _VTI_TYPE_AppNavigationItem;
  isChild?: boolean;
}
import { AKIcon } from '@ak_tools/components/media'
import { AKMenuItem, AKRouterMenuItemInner, AKTieredMenu } from '@ak_tools/components/menu'
import { AKTooltip } from '@ak_tools/components/overlay'
import { PrimeIcons } from 'primevue/api'
import { computed, ComputedRef, inject, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  isChild: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const mainMenuIsMaximized = inject('mainMenuIsMaximized') as ComputedRef<boolean>;
const akTieredMenu = ref<InstanceType<typeof AKTieredMenu> | null>(null);
const akIconContainer = ref<HTMLElement>();
const showChildren = ref(false);
const matchedChildren = ref<AKMenuItem[]>([]);
const collapsedMainMenuScrollableArea = ref();
const scrollContainerTop = ref(0);
const route = useRoute();
const router = useRouter();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const itemHasChildren = computed(() => props.item.children?.length ?? false);
const currentItemIsActive = computed(() => {
  return props.item.tags.includes(String(route.name)) ?? false;
});
const tieredMenuStyle = computed(() => {
  let position = { top: 0, left: 0 };
  if (akIconContainer.value) {
    const rect = akIconContainer.value.getBoundingClientRect();
    position = { top: rect.top + scrollContainerTop.value - scrollContainerTop.value, left: rect.left };
  }
  return `left: ${position.left + 45}px; top: ${position.top - 40}px`;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const handleScrollContainer = () => {
  scrollContainerTop.value = collapsedMainMenuScrollableArea.value.scrollTop;
};
const matchChildren = () => {
  matchedChildren.value = [
    {
      label: t(props.item.title),
      icon: props.item.icon,
      disabled: true,
    },
    {
      separator: true,
    },
  ];
  matchedChildren.value = props.item.children?.map(matchAppNavigationWithMenuItem) || [];
};
const toggleChildren = () => {
  showChildren.value = !showChildren.value;
};
const matchAppNavigationWithMenuItem = (appNavigationItem: AppNavigationItem) => {
  const newMenuItem: AKMenuItem = {
    label: t(appNavigationItem.title),
  };
  if (appNavigationItem.link) {
    newMenuItem.command = () => router.push({ name: appNavigationItem.name });
  }
  if (appNavigationItem.children?.length) {
    const itemChildren = appNavigationItem.children.map((child) => matchAppNavigationWithMenuItem(child));
    newMenuItem.items = itemChildren;
  }
  return newMenuItem;
};
const showTieredMenu = (event: Event) => {
  akTieredMenu.value?.showMenu(event);
};
const hideTieredMenu = () => {
  akTieredMenu.value?.hideMenu();
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onMenuItemClick: [];
}>();
const emitOnMenuItemClick = () => {
  emits('onMenuItemClick');
};
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  matchChildren();
  collapsedMainMenuScrollableArea.value = document.getElementById('mainMenuScrollableArea');
  collapsedMainMenuScrollableArea.value.addEventListener('scroll', handleScrollContainer);
});
onBeforeUnmount(() => {
  if (collapsedMainMenuScrollableArea.value) {
    collapsedMainMenuScrollableArea.value.removeEventListener('scroll', handleScrollContainer);
  }
});
</script>

<style lang="sass" scoped></style>
