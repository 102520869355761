<template>
  <AKSelect
    v-model:model-value="currentTournament"
    :selectable-options="tournaments"
    label="Turnier"
    :label-cols="2"
    @on-select="onSelect"
    @on-remove="onRemove">
    <template #placeholder>
      <span>Turnier auswählen</span>
    </template>
    <template #singleLabel="{ option }">
      <span>{{ option.name }}</span>
    </template>
    <template #option="{ option }">
      <span>{{ option.name }}</span>
    </template>
  </AKSelect>
</template>

<script setup lang="ts">
import { AKSelect } from '@ak_tools/components/select';
import { computed, inject } from 'vue';

import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments';
import { TournamentSelectDataHandler } from '@/components/layout/select/TournamentSelectDataHandler';

//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const dataHandler = inject('TournamentSelectDataHandler') as TournamentSelectDataHandler;

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const tournaments = computed(() => dataHandler.data.tournaments);
const currentTournament = computed(() => dataHandler.data.current_tournament);
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const onSelect = (tournament: BECommTournament) => {
  dataHandler.data.current_tournament = tournament;
  emits('on-tournament-select', tournament);
};

const onRemove = () => {
  dataHandler.data.current_tournament = null;
  emits('on-tournament-clear');
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits(['on-tournament-select', 'on-tournament-clear']);

//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
</script>

<style scoped></style>
