<template>
  <div class="ak-tabs flex h-full max-w-full grow flex-col">
    <div v-if="currentBreakpoint < AKBreakpoints.SM" class="w-full rounded-tl-xl bg-white px-4 py-2">
      <AKDialog
        v-model="showDialog"
        class="w-11/12"
        enable-custom-width
        headline-text="Ansicht auswählen"
        :show-cancel-button="false"
        :show-ok-button="false">
        <ul class="ak-flex-col ml-8 w-fit list-disc !gap-6 text-center">
          <li
            v-for="tab in tabs"
            :key="tab.title"
            class="cursor-pointer"
            @click="(activeTabHash = tab.hash), (showDialog = false)">
            <h6 v-if="tab.hash === activeTabHash" v-text="tab.title" />
            <span v-else v-text="tab.title" />
          </li>
        </ul>
      </AKDialog>

      <div class="text-primary flex w-fit cursor-pointer items-center gap-2 bg-white" @click="showDialog = true">
        <h6 class="overflow-hidden text-ellipsis" v-text="activeTab?.title" />
        <AKIcon :framework-icon="PrimeIcons.CHEVRON_DOWN" :scale="0.75" />
      </div>
    </div>

    <ul
      v-else
      class="ak-tabs--header ak-scrollbar-primary-tabs m-0 flex h-12 w-full list-none overflow-y-hidden p-0 text-center">
      <li
        v-for="tab in tabs"
        :key="tab.title"
        class="ak-tab--title m-0 mr-0.5 inline-block cursor-pointer whitespace-nowrap"
        :class="{ 'ak-tab--active': tab.hash === activeTabHash }"
        @click="activeTabHash = tab.hash">
        <div class="ak-tab--title-inner text-lg">
          <span :title="tab.title" v-text="tab.title" />
        </div>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { AKIcon } from '@ak_tools/components/media';
import { AKDialog } from '@ak_tools/components/overlay';
import { AKTabAddTabFunction, AKTabInterface } from '@ak_tools/components/panel';
import { AKStore } from '@ak_tools/start/App';
import { AKBreakpoints } from '@ak_tools/store/app/types';
import { PrimeIcons } from 'primevue/api';
import { computed, provide, ref, watchEffect } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const activeTabHash = ref('');
const tabs = ref<Array<AKTabInterface>>([]);
const showDialog = ref(false);

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const currentBreakpoint = computed(() => {
  return AKStore.App.state.breakpoint;
});

const activeTab = computed(() => {
  return tabs.value.find((tab) => tab.hash === activeTabHash.value);
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const addTab: AKTabAddTabFunction = (tab: AKTabInterface) => {
  tabs.value.push(tab);
};

//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
provide('addTab', addTab);
provide('activeTabHash', activeTabHash);

//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watchEffect(() => {
  if (tabs.value.length && !activeTabHash.value) {
    activeTabHash.value = tabs.value[0].hash;
  }
});
</script>

<style lang="sass" scoped>
@import "@/assets/ak_styles/theme"

.ak-tabs
  .ak-tabs--header
    .ak-tab--title
      float: left
      position: relative

      &-inner
        float: left
        padding: 0.75rem 2.5rem
        text-decoration: none
        color: $ak-theme-color-inv
        background: $gray-200
        border-top-left-radius: $ak-border-radius-xl
        border-top-right-radius: $ak-border-radius-xl
        border-bottom-right-radius: 0px
        border-bottom-left-radius: 0px


        &:hover span
          font-weight: bold

        span::after
          display: block
          content: attr(title)
          font-weight: bold
          height: 0
          overflow: hidden
          visibility: hidden

      &.ak-tab--active
        z-index: 1

        &:not(:first-child):before, &::after
          content: ""
          position: absolute
          bottom: 0
          width: $rounded-corner-rem
          aspect-ratio: 1
          background-color: $ak-theme-color

        &::before
          left: -$rounded-corner-rem
          -webkit-mask-image: $rounded-corner-mask-image-left
          mask-image: $rounded-corner-mask-image-left

        &::after
          right: -$rounded-corner-rem
          -webkit-mask-image: $rounded-corner-mask-image-right
          mask-image: $rounded-corner-mask-image-right

        .ak-tab--title-inner
          background: $ak-theme-color
          color: $ak-theme-color-inv
          font-weight: bold
</style>
