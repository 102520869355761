import { AKVueProvider } from '@ak_tools/framework_support/AKVueProvider';

import { BECommLocation, BECommReservation } from '@/backend_communication/definitions/BECommLocations';
import { BECommMatch } from '@/backend_communication/definitions/BECommMatches';
import { BECommRankedParticipant } from '@/backend_communication/definitions/BECommParticipants';
import { BECommReservationUpdateRequest } from '@/backend_communication/definitions/BECommReservations';
import { BECommTimeslot } from '@/backend_communication/definitions/BECommTimeslots';
import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments';
import BECommLocations from '@/backend_communication/implementations/BECommLocations';
import BECommMatches from '@/backend_communication/implementations/BECommMatches';
import BECommReservations from '@/backend_communication/implementations/BECommReservations';
import BECommTimeslots from '@/backend_communication/implementations/BECommTimeslots';

class ReservationDataHandlerDefaultData {
  elementsAreLoading = false;
  elementIsLoading = false;
  reservations: BECommReservation[] = [];
  matches: BECommMatch[] = [];
  current_reservation: BECommReservation | null = null;
  locations: BECommLocation[] = [];
  current_location: BECommLocation | null = null;
  current_participant: BECommRankedParticipant | null = null;
  current_tournament: BECommTournament | null = null;
  current_match_to_reserve: BECommMatch | null = null;
  current_timeslots: BECommTimeslot[] = [];
  allTimeSlots: BECommTimeslot[] = [];
}

export class ReservationDataHandler {
  private beCommReservations = new BECommReservations(new AKVueProvider());
  private beCommLocations = new BECommLocations(new AKVueProvider());
  private beCommMatches = new BECommMatches(new AKVueProvider());
  private beCommTimeslots = new BECommTimeslots(new AKVueProvider());

  data = new ReservationDataHandlerDefaultData();

  async getReservationsFromBackend() {
    this.data.elementsAreLoading = true;
    return this.beCommReservations
      .beCommGetReservations()
      .then((response) => {
        this.data.reservations = response.data.reservations;
      })
      .finally(() => {
        this.data.elementsAreLoading = false;
      });
  }

  async getReservationByIdFromBackend(reservation_id: number) {
    this.data.elementIsLoading = true;
    this.data.current_reservation = null;
    return this.beCommReservations
      .beCommGetReservationById(reservation_id)
      .then((response) => {
        this.data.current_reservation = response.data.reservation;
      })
      .finally(() => {
        this.data.elementIsLoading = false;
      });
  }

  async getTimeslotsByMatchDurationFromBackend() {
    this.data.elementsAreLoading = true;
    const tournament = this.data.current_tournament;
    if (!tournament) {
      return;
    }
    if (!tournament.match_duration) {
      return;
    }
    return this.beCommTimeslots
      .beCommGetTimeslotByMatchDuration(tournament.match_duration)
      .then((response) => {
        this.data.current_timeslots = response.data.timeslots;
      })
      .finally(() => {
        this.data.elementsAreLoading = false;
      });
  }

  async getLocationsFromBackend() {
    this.data.elementsAreLoading = true;
    return this.beCommLocations
      .beCommGetLocations(this.data.current_tournament?.tournament_id || 0)
      .then((response) => {
        this.data.locations = response.data.locations;
      })
      .finally(() => {
        this.data.elementsAreLoading = false;
      });
  }

  async getMatchesFromBackend() {
    this.data.elementsAreLoading = true;
    return this.beCommMatches
      .beCommGetMatches(this.data.current_tournament?.tournament_id || 0)
      .then((response) => {
        this.data.matches = response.data.matches;
      })
      .finally(() => {
        this.data.elementsAreLoading = false;
      });
  }

  async createReservationOnBackend() {
    this.data.elementIsLoading = true;
    return this.beCommReservations
      .beCommCreateReservation()
      .then((response) => {
        this.data.current_reservation = response.data.reservation;
      })
      .finally(() => {
        this.data.elementIsLoading = false;
      });
  }

  public getMatchIdByPlayers(player_one_id: number, player_two_id: number): number {
    const matches = this.data.matches;
    const match = matches.find(
      (m) =>
        (m.participant_one.person.person_id === player_one_id &&
          m.participant_two.person.person_id === player_two_id) ||
        (m.participant_one.person.person_id === player_two_id && m.participant_two.person.person_id === player_one_id),
    );
    if (match) {
      return match.match_id;
    }
    throw new Error('No match found');
  }
  public getAllTimeSlotsFromBackend() {
    this.data.elementsAreLoading = true;
    return this.beCommTimeslots
      .beCommGetTimeslots()
      .then((response) => {
        this.data.allTimeSlots = response.data.timeslots;
      })
      .finally(() => {
        this.data.elementsAreLoading = false;
      });
  }

  async updateReservationOnBackend(
    reservation_id: number,
    match_id: number,
    location_id: number,
    slot_id: number,
    date: number,
  ) {
    const reservationPutData: BECommReservationUpdateRequest = {
      match_id: match_id,
      location_id: location_id,
      slot_id: slot_id,
      date: date,
    };
    return this.beCommReservations.beCommUpdateReservation(reservation_id, reservationPutData).then((response) => {
      this.data.current_reservation = response.data.reservation;
      this.data.reservations.push(response.data.reservation);
    });
  }

  async deleteReservationByIdOnBackend(reservation_id: number | undefined) {
    if (!reservation_id) {
      return;
    }
    return this.beCommReservations.beCommDeleteReservation(reservation_id).then(() => {
      const index = this.data.reservations.findIndex((r) => r.reservation_id === reservation_id);
      if (index !== -1) {
        this.data.reservations.splice(index, 1);
      }
    });
  }
}
