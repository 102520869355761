<template>
  <AKSidePanel
    v-model:default-label-cols="labelCols"
    :show-maximize-button="false"
    :show-delete-button="false"
    @close-side-panel="emitCloseSidePanel">
    <template #headerLeft>
      <h4>{{ $t('ParticipantSidePanel.h4.result') }}</h4>
    </template>
    <template #headerAfterButtonMenu>
      <AKButton
        v-if="isNotCurrentUserParticipant"
        size="small"
        outlined
        :label="$t('ParticipantSidePanel.button.my-games')"
        @click="emitSetCurrentParticipant" />
    </template>

    <div class="sm:p-8">
      <div class="my-12 flex flex-col gap-6">
        <div class="flex gap-4">
          <span
            class="font-headline text-4xl font-bold md:text-6xl"
            v-text="'#' + providedParticipantHandler.data.current_participant?.rank" />

          <h2 class="flex flex-wrap gap-3 text-4xl md:text-6xl">
            <span class="font-normal" v-text="providedParticipantHandler.data.current_participant?.person.firstname" />
            <span class="font-bold" v-text="providedParticipantHandler.data.current_participant?.person.lastname" />
          </h2>
        </div>

        <div class="flex gap-8 pr-4">
          <div class="flex space-x-3">
            <span class="font-bold" v-text="$t('ParticipantSidePanel.label.average-points')" />
            <AKBadge>
              <span v-text="providedParticipantHandler.data.current_participant?.average_points" />
            </AKBadge>
          </div>
          <div class="flex space-x-3">
            <span class="font-bold" v-text="$t('ParticipantSidePanel.label.count-of-matches')" />
            <AKBadge>
              <span v-text="returnCountOfPlayedMatches()" />
            </AKBadge>
          </div>
          <div class="flex space-x-3">
            <span class="font-bold" v-text="$t('ParticipantSidePanel.label.points')" />
            <AKBadge>
              <span v-text="providedParticipantHandler.data.current_participant?.points" />
            </AKBadge>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <h4 class="mb-2" v-text="$t('ParticipantSidePanel.button.my-games.label')"></h4>
        <AKDataTable
          v-model:table-data="providedParticipantHandler.data.current_participant!.matches"
          data-key="match_id"
          table-column-size="sm"
          :selection-mode="'single'"
          show-pagination>
          <AKColumn field="match_day_number" :header="'Spiel'" sortable></AKColumn>
          <AKColumn field="participant_one_vs_two_names" :header="'Begegnung'" sortable></AKColumn>
          <AKColumn field="participant_one_vs_two_score" :header="'Stand'" sortable>
            <template #body="props">
              <div @click="onRowClick(props.data)">
                {{ props.data.participant_one_vs_two_score }}
              </div>
            </template>
          </AKColumn>
        </AKDataTable>
      </div>
    </div>
  </AKSidePanel>
</template>

<script setup lang="ts">
import { AKButton } from '@ak_tools/components/button';
import { AKColumn, AKDataTable } from '@ak_tools/components/data';
import { AKSidePanel } from '@ak_tools/components/layout';
import { AKBadge } from '@ak_tools/components/misc';
import { AKStore } from '@ak_tools/start/App';
import { computed, defineEmits, inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { BECommMatch } from '@/backend_communication/definitions/BECommMatches';
import { ParticipantDataHandler } from '@/components/participants/ParticipantDataHandler';
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const providedParticipantHandler = inject('ParticipantDataHandler') as ParticipantDataHandler;
const labelCols = ref(4);
const router = useRouter();
const route = useRoute();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------

const isNotCurrentUserParticipant = computed(() => {
  const currentParticipant = providedParticipantHandler.data.current_participant;
  const userParticipant = providedParticipantHandler.data.participants.find(
    (p) => p.person.person_id === AKStore.User.state.person.personId,
  );

  return currentParticipant && currentParticipant.participant_id !== userParticipant?.participant_id;
});

//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const onRowClick = (matchRowData: BECommMatch) => {
  if (!isNotCurrentUserParticipant.value) {
    router.push({
      name: 'match-plan',
      params: { item_id: String(matchRowData.match_id) },
    });
  }
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------

const emits = defineEmits<{
  setCurrentParticipant: [];
  closeSidePanel: [];
}>();
const emitCloseSidePanel = () => {
  emits('closeSidePanel');
};
const emitSetCurrentParticipant = () => {
  emits('setCurrentParticipant');
};
const returnCountOfPlayedMatches = () => {
  const currentParticipant = providedParticipantHandler.data.current_participant;

  if (!currentParticipant || !currentParticipant.matches) {
    return 0;
  }

  const playedMatches = currentParticipant.matches.filter(
    (match) => match.participant_one_score !== null && match.participant_two_score !== null,
  );

  return playedMatches.length;
};
</script>

<style lang="sass" scoped></style>
