import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';

import { BECommMatch } from '../definitions/BECommMatches';
import {
  BECommAddParticipantRequest,
  BECommParticipantCreateResponseR,
  BECommParticipantDeleteResponseR,
  BECommParticipantGetResponseR,
  BECommParticipantsGetResponseR,
  BECommParticipantUpdateRequest,
  BECommParticipantUpdateResponseR,
  BECommPerson,
} from '../definitions/BECommParticipants';
import { BECommTournament } from '../definitions/BECommTournaments';

export default class BECommParticipants extends BeCommAll {
  public beCommGetParticipants(tournament_id: number): Promise<BECommParticipantsGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommParticipants.error.get.participants.message'),
    };
    return this.beComm.backendCommGet(`/participants?tournament_id=${tournament_id}`, { timeout: 6000 }, messages, {
      returnOnError: false,
    });
  }

  public beCommGetParticipantById(participant_id: number): Promise<BECommParticipantGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommParticipants.error.get.participant.message'),
    };

    return this.beComm.backendCommGet(`/participants/${participant_id}`, { timeout: 10000 }, messages, {
      returnOnError: false,
    });
  }

  public beCommDeleteParticipant(participantId: number): Promise<BECommParticipantDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommParticipants.error.delete.participant.message'),
    };

    return this.beComm.backendCommDelete(`/participants/${participantId}`, {}, messages);
  }

  public beCommCreateParticipant(
    points: number,
    count_of_matches: number,
    average_points: number,
    person: BECommPerson,
    tournament: BECommTournament,
    matches: Array<BECommMatch>,
  ): Promise<BECommParticipantCreateResponseR> {
    const postBody: BECommAddParticipantRequest = {
      points: points,
      count_of_matches: count_of_matches,
      average_points: average_points,
      person: person,
      tournament: tournament,
      matches: matches,
    };

    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommParticipants.error.post.participant.message'),
    };

    return this.beComm.backendCommPost('/Participants', postBody, {}, messages);
  }

  public beCommUpdateParticipant(
    participant_id: number,
    putBody: BECommParticipantUpdateRequest,
  ): Promise<BECommParticipantUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('BECommParticipants.error.update.participant.message'),
    };

    return this.beComm.backendCommPut(`/Participants/${participant_id}`, putBody, {}, messages);
  }
}
