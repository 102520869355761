<template>
  <div class="flex items-center" @click="emitOnMenuItemClick">
    <AKIcon
      v-if="props.icon"
      class="mr-4 flex-none"
      :framework-icon="props.icon"
      :bold="props.currentItemIsActive"
      :scale="1.25" />
    <span class="grow truncate" :class="{ 'font-bold': props.currentItemIsActive }" v-text="$t(props.title)" />
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  currentItemIsActive: boolean;
  title: string;
  icon?: string;
}
import { AKIcon } from '@ak_tools/components/media'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  icon: undefined,
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onMenuItemClick: [];
}>();
const emitOnMenuItemClick = () => {
  emits('onMenuItemClick');
};
</script>

<style scoped></style>
