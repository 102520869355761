<template>
  <AKDataTable
    v-model:table-data="providedDataHandler.data.tournaments"
    v-model:table-filters="filters"
    v-model:selected-rows="providedDataHandler.data.selectedTableRows"
    v-model:rows-per-page="rowsPerPage"
    data-key="tournament_id"
    table-column-size="sm"
    :selection-mode="multiSelectValue"
    show-pagination
    show-pagination-toggle
    :index-to-display="selectedIndex"
    @on-clear-filter-click="onClearFilterClick"
    @on-row-click="onRowClick">
    <AKColumn class="w-1/6" field="tournament_id" :header="'ID'" sortable>
      <template #filter="{ filterModel, filterCallback }">
        <AKInputText
          v-model="filterModel.value"
          class="p-column-filter"
          data-test="tournament-table-filter-id"
          size="small"
          placeholder=""
          @on-input="filterCallback(), checkFilterSet(filterModel.value)" />
      </template>
    </AKColumn>
    <AKColumn field="name" :header="'Name'" sortable>
      <template #filter="{ filterModel, filterCallback }">
        <AKInputText
          v-model="filterModel.value"
          class="p-column-filter"
          data-test="tournament-table-filter-name"
          size="small"
          placeholder=""
          @on-input="filterCallback(), checkFilterSet(filterModel.value)" /> </template
    ></AKColumn>
  </AKDataTable>
</template>

<script setup lang="ts">
interface PropsInterface {
  enableMultiSelect?: boolean;
}
import { AKColumn, AKDataTable, AKDataTableFilterMeta, AKDataTableRowClickEvent, AKFilterMatchMode } from '@ak_tools/components/data'
import { AKInputText } from '@ak_tools/components/form'
import { computed, inject, nextTick, ref, watch } from 'vue'
import { BECommTournament } from '@/backend_communication/definitions/BECommTournaments'
import { TournamentDataHandler } from '@/components/tournaments/TournamentDataHandler'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  enableMultiSelect: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const providedDataHandler = inject('TournamentDataHandler') as TournamentDataHandler;
const filters = ref<AKDataTableFilterMeta>({});
const filterSet = ref(false);
const selectedIndex = ref(0);
const rowsPerPage = ref(10);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const multiSelectValue = computed(() => (props.enableMultiSelect ? 'multiple' : 'single'));
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const initFilters = () => {
  filters.value = {
    tournament_id: { value: '', matchMode: AKFilterMatchMode.CONTAINS },
    name: { value: '', matchMode: AKFilterMatchMode.CONTAINS },
  };
};
const onRowClick = (tournamentRowEvent: AKDataTableRowClickEvent, tournamentRowData: BECommTournament) => {
  emitOnRowClick(tournamentRowEvent, tournamentRowData);
};
const onClearFilterClick = () => {
  initFilters();
};
const setSelectedIndex = () => {
  const selectedTournament = providedDataHandler.data.current_tournament;
  if (selectedTournament) {
    const itemIndex = providedDataHandler.data.tournaments.findIndex(
      (tournament) => tournament.tournament_id === selectedTournament.tournament_id,
    );
    if (itemIndex !== -1) {
      selectedIndex.value = itemIndex;
      providedDataHandler.data.selectedTableRows = [selectedTournament];
    }
  }
};
const checkFilterSet = (value: string) => {
  nextTick(() => {
    filterSet.value = value ? true : false;
  });
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch([() => providedDataHandler.data.current_tournament, () => providedDataHandler.data.tournaments], () => {
  if (!filterSet.value) {
    setSelectedIndex();
  }
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onRowClick: [AKDataTableRowClickEvent, BECommTournament];
}>();
const emitOnRowClick = (tournamentRowEvent: AKDataTableRowClickEvent, tournamentRowData: BECommTournament) => {
  emits('onRowClick', tournamentRowEvent, tournamentRowData);
};
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
initFilters();
</script>

<style lang="sass" scoped></style>
