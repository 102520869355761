import { AKVueProvider } from '@ak_tools/framework_support/AKVueProvider';
import { AKDateTime } from '@ak_tools/ts_modules/core/AKDateTime';

import { BECommLocation, BECommLocationUpdateRequest } from '@/backend_communication/definitions/BECommLocations';
import { BECommParticipant } from '@/backend_communication/definitions/BECommParticipants';
import { BECommTimeslot } from '@/backend_communication/definitions/BECommTimeslots';
import { BECommTournamentConLocationCreateRequest } from '@/backend_communication/definitions/BECommTournamentConLocations';
import {
  BECommTournament,
  BECommTournamentParticipantsPostRequest,
  BECommTournamentUpdateRequest,
} from '@/backend_communication/definitions/BECommTournaments';
import BECommLocations from '@/backend_communication/implementations/BECommLocations';
import BECommParticipants from '@/backend_communication/implementations/BECommParticipants';
import BECommTimeslots from '@/backend_communication/implementations/BECommTimeslots';
import BECommTournamentConLocations from '@/backend_communication/implementations/BECommTournamentConLocations';
import BECommTournaments from '@/backend_communication/implementations/BECommTournaments';

class TournamentDataHandlerDefaultData {
  tournaments: BECommTournament[] = [];
  current_tournament: BECommTournament | null = null;
  participants: BECommParticipant[] = [];
  timeslots: BECommTimeslot[] = [];
  tournament_locations: BECommLocation[] = [];
  all_locations: BECommLocation[] = [];
  elementsAreLoading = false;
  elementIsLoading = false;
  selectedTableRows: BECommTournament[] = [];
}

export class TournamentDataHandler {
  private beCommTournaments = new BECommTournaments(new AKVueProvider());
  private beCommTimeslots = new BECommTimeslots(new AKVueProvider());
  private beCommTournamentConLocations = new BECommTournamentConLocations(new AKVueProvider());
  private beCommLocations = new BECommLocations(new AKVueProvider());
  private beCommParticipants = new BECommParticipants(new AKVueProvider());

  data = new TournamentDataHandlerDefaultData();

  async getTournamentsFromBackend() {
    this.data.elementsAreLoading = true;
    return this.beCommTournaments
      .beCommGetTournaments()
      .then((response) => {
        this.data.tournaments = response.data.tournaments;
      })
      .finally(() => {
        this.data.elementsAreLoading = false;
      });
  }

  async getTournamentByIdFromBackend(tournament_id: number) {
    this.data.elementIsLoading = true;
    this.data.current_tournament = null;
    return this.beCommTournaments
      .beCommGetTournamentById(tournament_id)
      .then((response) => {
        this.data.current_tournament = response.data.tournament;
      })
      .finally(() => {
        this.data.elementIsLoading = false;
      });
  }

  async getLocationsFromBackend() {
    return this.beCommTournaments.beCommGetLocations().then((response) => {
      this.data.all_locations = response.data.locations;
    });
  }

  async getParticipantsByTournamentIdFromBackend() {
    if (this.data.current_tournament) {
      return this.beCommParticipants
        .beCommGetParticipants(this.data.current_tournament?.tournament_id)
        .then((response) => {
          this.data.participants = response.data.participants;
        });
    }
  }

  async getTimeslotsByMatchDurationFromBackend() {
    if (this.data.current_tournament!.match_duration) {
      this.data.timeslots = [];
      this.data.elementIsLoading = true;
      return this.beCommTimeslots
        .beCommTimeslotsByDuration(this.data.current_tournament!.match_duration)
        .then(async (response) => {
          if (response.data.timeslots.length > 0) {
            this.data.timeslots = response.data.timeslots;
          } else {
            await this.generateTimeslotsByMatchDurationFromBackend();
          }
        })
        .finally(() => {
          this.data.elementIsLoading = false;
        });
    }
  }

  async generateTimeslotsByMatchDurationFromBackend() {
    if (this.data.current_tournament) {
      if (this.data.current_tournament.match_duration) {
        return this.beCommTimeslots
          .beCommGenerateTimeslot(this.data.current_tournament.match_duration.toString())
          .then((response) => {
            this.data.timeslots = response.data.timeslots;
          });
      }
    }
  }

  async deleteTournamentByIdOnBackend(tournament_id: number) {
    return this.beCommTournaments.beCommDeleteTournament(tournament_id).then(() => {
      const index = this.data.tournaments.findIndex((t) => t.tournament_id === tournament_id);
      if (index !== -1) {
        this.data.tournaments.splice(index, 1);
      }
    });
  }

  async removeLocationFromTournament(location_id: number) {
    await this.beCommTournamentConLocations.beCommDeleteTournamentConLocation(
      this.data.current_tournament!.tournament_id,
      location_id,
    );
  }

  async addLocationToCurrentTournament(location_id: number) {
    const tournament_id = this.data.current_tournament!.tournament_id;

    const postBody: BECommTournamentConLocationCreateRequest = {
      tournament_id: tournament_id,
      location_id: location_id,
    };

    await this.beCommTournamentConLocations.beCommCreateTournamentConLocation(postBody);
  }

  async addParticipantToCurrentTournament(personal_user_group_id: number) {
    const postBody: BECommTournamentParticipantsPostRequest = {
      tournament_id: this.data.current_tournament!.tournament_id,
      personal_user_group_id: personal_user_group_id,
    };
    await this.beCommTournaments.beAddParticipantToTournament(postBody);
  }

  async createLocation(name: string) {
    const postBody: BECommLocationUpdateRequest = {
      name: name,
      description: name,
    };
    await this.beCommLocations.beCommCreateLocation().then((response) => {
      if (response.data.location) {
        this.beCommLocations
          .beCommUpdateLocation(response.data.location.location_id, postBody)
          .then(async (response) => {
            this.data.all_locations.push(response.data.location);
            this.data.current_tournament!.locations.push(response.data.location);
            await this.addLocationToCurrentTournament(response.data.location.location_id);
          });
      }
    });
  }

  async removeParticipantFromCurrentTournament(participantId: number) {
    await this.beCommParticipants.beCommDeleteParticipant(participantId).then(() => {
      this.getParticipantsByTournamentIdFromBackend();
    });
  }

  async createTournamentOnBackend() {
    let newTournament: BECommTournament | null = null;
    this.data.elementIsLoading = true;
    this.data.timeslots = [];
    this.data.participants = [];
    await this.beCommTournaments
      .beCommCreateTournament()
      .then(async (response) => {
        newTournament = response.data.tournament;
        if (newTournament) {
          this.data.tournaments.push(newTournament);
          this.data.current_tournament = newTournament;
        }
      })
      .finally(() => {
        this.data.elementIsLoading = false;
      });

    return newTournament;
  }

  async updateTournamentBaseOnBackend(tournament: BECommTournament) {
    const akDateTime = new AKDateTime();
    if (
      tournament.start_date == 0 ||
      tournament.end_date == 0 ||
      tournament.start_date == null ||
      tournament.end_date == null
    ) {
      tournament.start_date = akDateTime.getCurrentTimeStamp();
      tournament.end_date = akDateTime.getCurrentTimeStamp();
    }
    const tournamentPutData: BECommTournamentUpdateRequest = {
      start_date: tournament.start_date,
      end_date: tournament.end_date,
      name: tournament.name,
      match_duration: tournament.match_duration,
    };
    return this.beCommTournaments
      .beCommUpdateTournament(tournament.tournament_id, tournamentPutData)
      .then((response) => {
        this.data.current_tournament = response.data.tournament;
        const index = this.data.tournaments.findIndex((t) => t.tournament_id === tournament.tournament_id);
        if (index !== -1) {
          this.data.tournaments[index] = this.data.current_tournament;
        }
      });
  }

  public async generateMatchPlan(tournament_id: number) {
    this.data.elementIsLoading = true;
    await this.beCommTournaments.beCommGenerateMatchPlan(tournament_id).finally(() => {
      this.data.elementIsLoading = false;
    });
  }
}
